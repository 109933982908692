import { usePaginationPageReturnType } from '@/hooks/usePaginationPage';
import { Button, cn, Pagination } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useDocumentVisibility } from 'ahooks';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseFlex } from '../baseFlex';
import { BaseLoadingDisplay } from '../baseLoadingDisplay';

/**
 * @component
 * PullToRefresh 是一个用于实现上拉记载和滚动加载更多功能的组件。当用户滚动到页面底部时，它会触发 `loadMore` 回调函数来加载更多数据，并根据加载状态展示相应的提示信息。
 * @param props - PullToRefreshProps
 * @param props.loadMore - 用于请求加载更多数据的回调函数。
 * @param props.children - 需要下拉刷新和滚动加载更多的内容区域。
 * @param props.errorMessage - 加载失败时显示的错误信息，默认为翻译后的“出错了，点击重试”。
 * @param props.loadingText - 数据加载中显示的文字，默认为翻译后的“加载中…”。
 * @param props.loadMoreText - 当没有更多数据可加载时显示的文字，默认为翻译后的“已经到底了”。
 * @param props.error - 标记是否发生加载错误的布尔值。
 * @param props.total - 总数据项数量。
 * @param props.list - 已经加载的数据项数组。
 * @param props.loading - 标记当前是否正在加载数据的布尔值。
 * @param props.getList - 用于重新加载数据的回调函数（通常在加载错误时使用）。
 *
 * @returns 渲染带有滚动加载逻辑和相应提示信息的 React 组件。
 */
export default function PullToRefresh(
  props: PullToRefreshProps & usePaginationPageReturnType,
) {
  const { t } = useTranslation();

  const {
    children,
    loadMore,
    errorMessage = t('出错了，点击重试'),
    loadingText = t('加载中...'),
    loadMoreText = t('已经全部加载完毕'),
    total,
    list,
    error,
    loading,
    getList,
    scrollElement,
    ready = true, // 传 false 不能加载更多，不会影响首次加载
    loadingMore = true,
    className,
    pcPaginator = true,
    totalPage,
    pageNo,
    setParams,
    hideOnlyOnePagination,
    loadingDisplay,
  } = props;
  const documentVisibility = useDocumentVisibility();

  //是否不可见
  const isHidden = 'hidden' === documentVisibility;
  const { isMobile } = useModel('system');
  useEffect(() => {
    const handleScroll = () => {
      if (!isMobile && pcPaginator) return;

      if (!ready || isHidden) return;

      const { scrollTop } = scrollElement || document.documentElement;
      const scrollHeight = scrollElement
        ? scrollElement.scrollHeight
        : document.documentElement.scrollHeight;
      const elementHeight = scrollElement
        ? scrollElement.getBoundingClientRect().height
        : window.innerHeight;

      if (
        scrollTop + elementHeight >= scrollHeight &&
        total > list?.length &&
        !loading
      ) {
        loadMore();
      }
    };

    const scrollTarget = scrollElement || window;
    scrollTarget.addEventListener('scroll', handleScroll);
    return () => scrollTarget.removeEventListener('scroll', handleScroll);
  }, [loadMore, total, list, scrollElement, loading, ready]);

  const renderLoadMore = () =>
    total === list.length && (
      <div className="text-auxiliaryTextColor text-sm mt-[10px] ">
        {loadMoreText}
      </div>
    );

  const renderError = () =>
    error && (
      <div
        onClick={() => {
          getList();
        }}
        className="text-auxiliaryTextColor text-sm mt-[10px] "
      >
        {errorMessage}
      </div>
    );

  const renderLoading = () =>
    loading && (
      <div className="text-auxiliaryTextColor text-sm mt-[10px] ">
        {loadingText}
      </div>
    );

  const renderLoadMoreButton = () => {
    if (loading) return null;

    if (list.length && total > list.length && loadingMore) {
      return (
        <Button
          isLoading={loading}
          variant="solid"
          size="sm"
          onClick={loadMore}
          className="!bg-backgroundAuxiliaryColor mt-[10px] !text-foreground"
        >
          {t('加载更多')}
        </Button>
      );
    } else {
      return null;
    }
  };

  // 是否显示分页器
  const isShowPagination = useMemo(() => {
    return (
      (!isMobile && pcPaginator && !hideOnlyOnePagination) ||
      (!isMobile && pcPaginator && hideOnlyOnePagination && totalPage > 1)
    );
  }, [pcPaginator, totalPage, hideOnlyOnePagination, isMobile]);

  return (
    <div className={cn('w-full', className)}>
      <BaseLoadingDisplay loading={loading} list={list} isShowDisplayLoading={loadingDisplay}
                          customEmpty={props.customEmpty}>
        {children}
      </BaseLoadingDisplay>

      {/*移动端下拉刷新*/}
      {total > 0 && (
        <BaseFlex
          justify="center"
          align="center"
          className={cn(`w-full min-h-12 pt10 pb10`, {
            'sm:hidden': pcPaginator,
          })}
        >
          {!loading && renderLoadMore()}
          {renderError()}
          {renderLoading()}
          {total > list.length && !error && !loading && renderLoadMoreButton()}
        </BaseFlex>
      )}

      {/*Pc分页*/}
      {total > 0 && isShowPagination && (
        <div className={cn(`flex justify-center mt-[10px]`)}>
          {renderError()}
          {!error && (
            <Pagination
              className="text-foreground"
              classNames={{ cursor: ' text-primaryButtonTextColor' }}
              loop
              showControls
              onChange={(page) => {
                loadMore(page);
                if (page === 1) {
                  setParams((prev: any) => {
                    return { ...prev, pageNo: page };
                  });
                }
              }}
              color="primary"
              total={totalPage!}
              page={pageNo}
            />
          )}
        </div>
      )}
      {!isMobile && pcPaginator && hideOnlyOnePagination && totalPage === 1 && (
        <div className="text-auxiliaryTextColor text-center text-sm mt-[10px] ">
          {/* {loadMoreText} */}
        </div>
      )}
    </div>
  );
}

export interface PullToRefreshProps {
  children: ReactNode;
  errorMessage?: string;
  loadingText?: string;
  loadMoreText?: string;
  className?: string;
  loading: boolean;
  loadingDisplay?: boolean; //内置的加载 和 空状态 ，传false关闭
  ready?: boolean;
  scrollElement?: HTMLElement;
  loadingMore?: boolean;
  customEmpty?: React.ReactNode;
}
